/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled } from '@material-ui/core';
import { graphql } from 'gatsby';
import React, { FormEvent, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Col,
  Container,
  FormInput,
  Typography,
} from 'components/UI';
import { IconSearch } from 'components/UI/Icons';
import { Breakpoints } from 'utils/enums';
import { textByLine } from 'utils/textByLine';

// Types

export interface FaqSectionContentProps {
  metadata: MetaData;
  faq: {
    categories: string[];
    answer: string;
    question: string;
  }[];
  main: {
    title: string;
    text: string;
  };
}

export interface FaqSectionProps {
  faqContent: FaqSectionContentProps;
}

// Components

const FaqSection = styled('section')(({ theme }) => ({
  paddingBottom: theme.spacing(10),
  paddingTop: theme.spacing(5),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    paddingBottom: 0,
    paddingTop: theme.spacing(30),
  },
}));

const MainTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(8),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    ...theme.typography.h1,
    marginBottom: theme.spacing(10),
  },
}));

const MainCol = styled(Col)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const FaqCol = styled(Col)(({ theme }) => ({
  marginTop: theme.spacing(15),
  paddingLeft: theme.typography.pxToRem(0),
  position: 'relative',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginTop: 0,
    paddingLeft: theme.typography.pxToRem(25),
  },
}));

const FaqSearch = styled('form')(({ theme }) => ({
  marginBottom: theme.spacing(4),
  maxWidth: '100%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginBottom: theme.spacing(10),
    maxWidth: theme.typography.pxToRem(480),
  },
}));

const FaqSectionTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  ...theme.typography.h4,

  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginBottom: theme.spacing(5.5),
    ...theme.typography.h3,
  },
}));

const FaqItem = styled('section')(({ theme }) => ({
  marginBottom: theme.spacing(10),
}));

const FaqQuestionSection = styled('article')({});

// do nothing since the FAQ updates on change
const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) =>
  e.preventDefault();

const Faq: React.FC<FaqSectionProps> = ({ faqContent }) => {
  // Sets the string to be searched
  const [search, setSearch] = useState('');

  const dataParsed = faqContent.faq.reduce<
    Record<string, { question: string; answer: string; categories: string[] }[]>
  >((acc, item) => {
    const { categories } = item;

    categories.forEach((cat) => {
      if (acc[cat]) {
        acc[cat] = [...acc[cat], item];
      } else {
        acc[cat] = [item];
      }
    });

    return acc;
  }, {});

  const resultsOfSearch = Object.keys(dataParsed).map((categoryName) => {
    const list = dataParsed[categoryName];

    // Filters the rendered FAQ's if the searched string is present either at questions or answers
    const filteredFaqQuestion = list.filter((faqQuestion) => {
      const question = faqQuestion.question.toLowerCase();
      const answers = faqQuestion.answer.toLowerCase();
      return (
        question.indexOf(search.toLowerCase()) !== -1 ||
        answers.indexOf(search) !== -1
      );
    });

    if (filteredFaqQuestion.length > 0) {
      return (
        <FaqItem key={categoryName}>
          <FaqSectionTitle>{categoryName}</FaqSectionTitle>

          {filteredFaqQuestion.map((result) => {
            return (
              <FaqQuestionSection key={result.question}>
                <Accordion variant="dark" key={result.question}>
                  <AccordionSummary>{result.question}</AccordionSummary>
                  <AccordionDetails>
                    {textByLine(result.answer).map((item, index) => (
                      <Typography
                        gutterBottom
                        key={index}
                        component="span"
                        variant="body1"
                        color="textSecondary"
                      >
                        <div dangerouslySetInnerHTML={{ __html: item }} />
                      </Typography>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </FaqQuestionSection>
            );
          })}
        </FaqItem>
      );
    }

    return null;
  });

  return (
    <FaqSection>
      <Container>
        <MainCol sm={12} md={5}>
          <MainTitle variant="h3">{faqContent.main.title}</MainTitle>
          <FaqSearch onSubmit={handleSearchSubmit}>
            <FormInput
              name="search"
              placeholder="What would you like to know?"
              value={search}
              onChange={(event: {
                target: { value: React.SetStateAction<string> };
              }) => setSearch(event.target.value)}
              adornment={<IconSearch />}
            />
          </FaqSearch>
          {textByLine(faqContent.main.text).map((item) => (
            <Typography key={item} variant="body1">
              {item}
            </Typography>
          ))}
        </MainCol>
        <FaqCol md={7}>{resultsOfSearch}</FaqCol>
      </Container>
    </FaqSection>
  );
};

const query = graphql`
  fragment FaqContent on FaqYaml {
    faq {
      answer
      question
      categories
    }
    main {
      title
      text
    }
  }
`;

export { Faq, query };
