/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { graphql } from 'gatsby';
import React from 'react';

import { Faq, FaqSectionContentProps } from 'components/contents/Faq';
import Layout from 'components/partials/Layout';
import { Overflow } from 'components/partials/Overflow';

// Types

interface FaqProps {
  data: {
    faqYaml: FaqSectionContentProps;
  };
}

const FaqPage: React.FC<FaqProps> = ({ data }) => {
  return (
    <Overflow>
      <Layout
        title={data.faqYaml.metadata.title}
        description={data.faqYaml.metadata.description}
      >
        <Faq faqContent={data.faqYaml} />
      </Layout>
    </Overflow>
  );
};

export const query = graphql`
  query FaqQuery {
    faqYaml {
      metadata {
        title
        description
      }
      ...FaqContent
    }
  }
`;

export default FaqPage;
